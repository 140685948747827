import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
    FormGroup,
    ReactiveFormsModule,
    FormGroupDirective,
} from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { MatChipsModule } from '@angular/material/chips'
import { MatIconModule } from '@angular/material/icon'
import {
    StepDirection,
    StepEvent,
    Steps,
} from '../../../interfaces/masjid.form.steps.interface'
import { AuthService } from 'src/app/shared/services/auth.service'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { User } from 'src/app/auth/interfaces'
import { Observable } from 'rxjs/internal/Observable'
import { TranslocoModule } from '@ngneat/transloco'
import { Store, select } from '@ngrx/store'
import { userSelector } from 'src/app/auth/store/selectors/auth.selectors'
import { take } from 'rxjs/operators'

@Component({
    selector: 'app-masjid-admins',
    templateUrl: './masjid-admins.component.html',
    styleUrl: './masjid-admins.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        MatProgressSpinnerModule,
        TranslocoModule,
    ],
})
export class MasjidAdminsComponent implements OnInit {
    adminsformGroup: FormGroup
    @Output() changeStep = new EventEmitter<StepEvent>()
    @Output() adminsSelected = new EventEmitter<string[]>()
    @Input() formGroupName: string
    @Input() isSubmittingSave$: Observable<boolean>

    selectedAdmins: User[] = []
    isLoading = false
    errorMessage: string | null = null
    showUserNotFoundError = false
    currentUser: User;

    constructor(
        private _authService: AuthService,
        private rootFormGroup: FormGroupDirective,
        private _store: Store
    ) {
        // Get current user from store
        this._store
            .pipe(select(userSelector), take(1))
            .subscribe((user) => {
                if (user) {
                    this.currentUser = user;
                }
            });
    }

    ngOnInit(): void {
        this.adminsformGroup = this.rootFormGroup.control.get(
            this.formGroupName
        ) as FormGroup
    }

    onAddAdmin(email: string): void {
        console.log('Attempting to add admin with email:', email);
        
        if (!email) {
            console.log('No email provided');
            this.errorMessage = 'adminEmailInvalid';
            this.showUserNotFoundError = true;
            return;
        }

        if (this.selectedAdmins.some(admin => admin.email === email)) {
            console.log('Admin already exists:', email);
            this.errorMessage = 'adminEmailExists';
            this.showUserNotFoundError = true;
            return;
        }

        // Check if trying to add current user as admin
        if (this.currentUser.email.toLowerCase() === email.toLowerCase()) {
            console.log('Cannot add creator as admin:', email);
            this.errorMessage = 'cannotAddOwnerAsAdmin';
            this.showUserNotFoundError = true;
            return;
        }

        this.isLoading = true;
        this.errorMessage = null;
        this.showUserNotFoundError = false;

        console.log('Calling getUserByEmail service...');
        this._authService.getUserByEmail(email).subscribe({
            next: (user) => {
                console.log('Service response:', user);
                if (user) {
                    // Double check to prevent creator from being added as admin
                    if (user.$id === this.currentUser.$id) {
                        console.log('Cannot add creator as admin');
                        this.errorMessage = 'cannotAddOwnerAsAdmin';
                        this.showUserNotFoundError = true;
                        this.isLoading = false;
                        return;
                    }

                    console.log('Adding user to admins:', user);
                    this.selectedAdmins.push(user);
                    this.adminsSelected.emit(
                        this.selectedAdmins.map((admin) => admin.$id)
                    );
                    this.errorMessage = 'adminAddSuccess';
                    this.showUserNotFoundError = false;
                } else {
                    console.log('User not found for email:', email);
                    this.showUserNotFoundError = true;
                    this.errorMessage = 'userNotRegistered';
                }
                this.isLoading = false;
            },
            error: (error) => {
                console.error('Error in onAddAdmin:', error);
                this.errorMessage = 'adminNotFound';
                this.showUserNotFoundError = true;
                this.isLoading = false;
            },
        });
    }

    removeAdmin(admin: User): void {
        console.log('Removing admin:', admin);
        const index = this.selectedAdmins.findIndex(a => a.$id === admin.$id);
        if (index >= 0) {
            this.selectedAdmins.splice(index, 1);
            this.adminsSelected.emit(
                this.selectedAdmins.map((admin) => admin.$id)
            );
            this.errorMessage = 'adminRemoveSuccess';
            this.showUserNotFoundError = false;
            console.log('Admin removed, remaining admins:', this.selectedAdmins);
        }
    }

    onNext(): void {
        this.changeStep.emit({
            direction: StepDirection.Finish,
            currentStep: Steps.AdminsInfo,
        })
    }

    onBack(): void {
        this.changeStep.emit({
            direction: StepDirection.Back,
            currentStep: Steps.AdminsInfo,
        })
    }
}
