import { CommonModule } from '@angular/common'
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatLabel, MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { TranslocoModule } from '@ngneat/transloco'
import { AVAILABLE_FACILITIES } from 'src/app/masjids/interfaces/masjidData.interface'
import {
    StepDirection,
    StepEvent,
    Steps,
} from 'src/app/masjids/interfaces/masjid.form.steps.interface'
import { PhotoUploadComponent } from 'src/app/shared/components/file-upload/photo-upload/photo-upload.component'
import {
    PhotoInterface,
    PhotoType,
} from 'src/app/shared/interfaces/Image.interface'
import Utils from 'src/app/shared/services/utils'
import { TimeValidators } from 'src/app/shared/validators/time.validator'
import { distinctUntilChanged, debounceTime } from 'rxjs/operators'

@Component({
    selector: 'app-basic-information',
    templateUrl: './basic-information.component.html',
    styleUrls: ['./basic-information.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        MatLabel,
        MatFormFieldModule,
        ReactiveFormsModule,
        CommonModule,
        MatAutocompleteModule,
        MatSelectModule,
        PhotoUploadComponent,
        TranslocoModule,
        MatButtonModule,
        MatInputModule
    ],
})
export class BasicInformationComponent implements OnInit {
    public masjidBasicInfo: FormGroup
    public openHour: Array<number> = Utils.arrayRange(1, 25)
    public closeHour: Array<number> = Utils.arrayRange(1, 25)
    selectedOpenHour: number
    selectedCloseHour: number
    public StepDirection = StepDirection
    public photoType: PhotoType = PhotoType.Masjid
    public availableFacilities = AVAILABLE_FACILITIES

    @Output() changeStep: EventEmitter<StepEvent> = new EventEmitter<StepEvent>()
    @Output() masjidImages: EventEmitter<PhotoInterface[]> = new EventEmitter<PhotoInterface[]>()
    @Input() isUserLoggedIn: boolean
    @Input() formGroupName: string
    @Input() bucketId: string
    @Input() initFiles: PhotoInterface[]

    constructor(private rootFormGroup: FormGroupDirective) {}

    ngOnInit(): void {
        this.masjidBasicInfo = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
        
        // Improved subscription with debounce and distinct changes
        this.masjidBasicInfo.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(() => {
            if (this.masjidBasicInfo.touched || this.masjidBasicInfo.dirty) {
                this.logValidationState();
            }
        });

        if (this.initFiles?.length > 0) {
            this.initFiles = this.initFiles.filter(
                (file, index, self) =>
                    file.photoType === PhotoType.Masjid &&
                    self.findIndex((f) => f.id === file.id) === index
            );
        }
    }

    private logValidationState() {
        const nameControl = this.masjidBasicInfo.get('name');
        if (nameControl?.errors) {
            console.log('Name field errors:', nameControl.errors);
        }
        console.log('Form valid:', this.masjidBasicInfo.valid);
        console.log('Form errors:', this.masjidBasicInfo.errors);
    }

    onOpenHourChange() {
        TimeValidators.markTimeFieldsAsTouched(this.masjidBasicInfo);
    }

    onCloseHourChange() {
        TimeValidators.markTimeFieldsAsTouched(this.masjidBasicInfo);
    }

    validateForm() {
        const nameControl = this.masjidBasicInfo.get('name');
        nameControl?.markAsTouched();
        TimeValidators.markTimeFieldsAsTouched(this.masjidBasicInfo);

        if (nameControl?.errors) {
            console.log('Name field errors:', nameControl.errors);
        }

        console.log('Form valid:', this.masjidBasicInfo.valid);
        console.log('Form errors:', this.masjidBasicInfo.errors);
    }

    doChangeStep(direction: StepDirection) {
        // Mark all fields as touched to trigger validation
        Object.keys(this.masjidBasicInfo.controls).forEach(key => {
            const control = this.masjidBasicInfo.get(key);
            control?.markAsTouched();
            control?.markAsDirty();
        });

        // Force form validation
        this.masjidBasicInfo.updateValueAndValidity();
        this.validateForm();

        if (!this.masjidBasicInfo.valid) {
            console.log('Form is invalid');
            return;
        }

        if (direction === 'next') {
            this.changeStep.emit({
                currentStep: Steps.BasicInfo,
                direction: direction,
            });
        }
    }

    sendFilesToParentForm(files: PhotoInterface[]) {
        this.masjidImages.emit(files);
    }
}
