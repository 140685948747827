<div class="admins-container" [formGroup]="adminsformGroup" *transloco="let t; read: 'masjidData'">
  <h2>{{ t('addAdministrators') }}</h2>
  <p class="description">
    {{ t('addAdministratorsDescription') }}
  </p>

  <div class="admin-input-container">
    <mat-form-field appearance="outline" class="admin-input">
      <mat-label>{{ t('administratorEmail') }}</mat-label>
      <input matInput #adminEmail type="email" placeholder="{{ t('administratorEmail') }}">
    </mat-form-field>
    <button mat-raised-button color="primary" 
            [disabled]="isLoading || !adminEmail.value"
            (click)="onAddAdmin(adminEmail.value)">
      <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
      <span *ngIf="!isLoading">{{ t('addAdmin') }}</span>
    </button>
  </div>

  <div class="notification-message" *ngIf="errorMessage" 
       [ngClass]="{'success': errorMessage === 'adminAddSuccess' || errorMessage === 'adminRemoveSuccess',
                  'error': errorMessage === 'adminEmailExists' || errorMessage === 'adminNotFound' || 
                          errorMessage === 'userNotRegistered' || errorMessage === 'cannotAddOwnerAsAdmin'}">
    {{ t(errorMessage) }}
  </div>

  <div class="selected-admins" *ngIf="selectedAdmins.length > 0">
    <h3>{{ t('selectedAdministrators') }}:</h3>
    <div class="admin-list">
      <div class="admin-item" *ngFor="let admin of selectedAdmins">
        <div class="admin-info">
          <mat-icon class="admin-icon">person</mat-icon>
          <div class="admin-details">
            <span class="admin-name">{{ admin.firstName }} {{ admin.lastName }}</span>
            <span class="admin-email">{{ admin.email }}</span>
          </div>
        </div>
        <button mat-icon-button color="warn" (click)="removeAdmin(admin)" aria-label="Remove admin">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="button-container">
    <button type="button" mat-button (click)="onBack()">{{ t('back') }}</button>
    <button type="submit" mat-raised-button color="primary" (click)="onNext()">
      {{ t('finish') }}
    </button>
  </div>
</div>
