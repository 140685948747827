import { CommonModule } from '@angular/common'
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core'
import {
    FormGroup,
    FormGroupDirective,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatLabel, MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslocoModule } from '@ngneat/transloco'
import { Observable, map, of, startWith, BehaviorSubject } from 'rxjs'
import {
    StepDirection,
    StepEvent,
    Steps,
} from 'src/app/masjids/interfaces/masjid.form.steps.interface'
import { Country } from 'src/app/shared/interfaces/country.interface'
import { GeolocationService } from 'src/app/shared/services/geolocation.service'
import utils from 'src/app/shared/services/utils'
import { CountryValidators } from 'src/app/shared/validators/country.validator'
import { FormValidators } from 'src/app/shared/validators/form.validator'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'

@Component({
    selector: 'app-masjid-address',
    templateUrl: './masjid-address.component.html',
    styleUrls: ['./masjid-address.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        MatLabel,
        MatFormFieldModule,
        ReactiveFormsModule,
        CommonModule,
        MatAutocompleteModule,
        TranslocoModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule
    ],
})
export class MasjidAddressComponent implements OnInit {
    @ViewChild('mapContainer') mapContainer!: ElementRef;
    
    private backendErrorSubject = new BehaviorSubject<string | null>(null);
    backendError$ = this.backendErrorSubject.asObservable();
    
    public masjidAddressInfo: FormGroup
    public StepDirection = StepDirection
    selectedCountry: Country | null = null;
    isAddressLookupLoading = false;
    showMap = false;
    private mapInitialized = false;

    @Output() changeStep: EventEmitter<StepEvent> = new EventEmitter<StepEvent>()
    @Input() formGroupName: string
    filteredCountries$?: Observable<Country[]>
    @Input() countries: Country[]
    displayFn = CountryValidators.displayFn;

    constructor(
        private rootFormGroup: FormGroupDirective,
        private geolocationService: GeolocationService
    ) {}

    ngOnInit(): void {
        this.masjidAddressInfo = this.rootFormGroup.control.get(
            this.formGroupName
        ) as FormGroup;

        const countryControl = this.masjidAddressInfo.get('country');
        if (countryControl) {
            this.filteredCountries$ = countryControl.valueChanges.pipe(
                startWith(''),
                map(value => CountryValidators.filterCountriesSync(value || '', this.countries))
            );

            countryControl.valueChanges.subscribe(value => {
                console.log('Country control value changed:', value);
            });
        }

        // Reset formatted address when any address field changes
        const addressFields = ['street', 'houseNumber', 'city', 'state', 'postalCode', 'country'];
        addressFields.forEach(field => {
            this.masjidAddressInfo.get(field)?.valueChanges.subscribe(() => {
                if (this.masjidAddressInfo.get('formattedAddress')?.value) {
                    this.masjidAddressInfo.patchValue({
                        formattedAddress: null,
                        latitude: null,
                        longitude: null
                    });
                    this.showMap = false;
                }
            });
        });
    }

    private async initializeMap(position: { lat: number, lng: number }) {
        if (this.mapInitialized) {
            // If map is already initialized, just update the location
            this.geolocationService.updateMapLocation(position);
            return;
        }

        try {
            // Wait a bit for the container to be properly rendered
            await new Promise(resolve => setTimeout(resolve, 100));
            
            await this.geolocationService.initializeMap(this.mapContainer.nativeElement, {
                center: position,
                zoom: 16
            });
            
            this.mapInitialized = true;
            this.geolocationService.updateMapLocation(position);
        } catch (error) {
            console.error('Error initializing map:', error);
            this.backendErrorSubject.next('Error initializing map. Please try again.');
        }
    }

    lookupAddress(): void {
        this.isAddressLookupLoading = true;
        // Clear any previous errors
        this.backendErrorSubject.next(null);
        
        const addressParts = [
            this.masjidAddressInfo.get('street')?.value,
            this.masjidAddressInfo.get('houseNumber')?.value,
            this.masjidAddressInfo.get('city')?.value,
            this.masjidAddressInfo.get('state')?.value,
            this.masjidAddressInfo.get('postalCode')?.value,
            this.masjidAddressInfo.get('neighborhood')?.value,
            typeof this.masjidAddressInfo.get('country')?.value === 'object' 
                ? this.masjidAddressInfo.get('country')?.value.name 
                : this.masjidAddressInfo.get('country')?.value
        ].filter(Boolean);

        const addressString = addressParts.join(', ');

        this.geolocationService.getCoordinatesFromAddress(addressString)
            .subscribe({
                next: async (result) => {
                    // Clear any existing errors on success
                    this.backendErrorSubject.next(null);
                    
                    // Parse the formatted address
                    const parsedAddress = this.geolocationService.parseGoogleAddress(result.formattedAddress);
                    console.log('Updating form with parsed address:', parsedAddress);
                    
                    // Find the country object that matches the parsed country name
                    let countryObject = this.countries.find(
                        country => country.name.toLowerCase() === parsedAddress.country?.toLowerCase()
                    );

                    // If not found, try to keep the existing country
                    if (!countryObject && this.masjidAddressInfo.get('country')?.value) {
                        countryObject = this.masjidAddressInfo.get('country')?.value;
                    }
                    
                    // Update form with the parsed address and coordinates
                    this.masjidAddressInfo.patchValue({
                        street: parsedAddress.street || this.masjidAddressInfo.get('street')?.value,
                        houseNumber: parsedAddress.houseNumber || this.masjidAddressInfo.get('houseNumber')?.value,
                        neighborhood: parsedAddress.neighborhood || this.masjidAddressInfo.get('neighborhood')?.value,
                        city: parsedAddress.city || this.masjidAddressInfo.get('city')?.value,
                        state: parsedAddress.state || this.masjidAddressInfo.get('state')?.value,
                        postalCode: parsedAddress.postalCode || this.masjidAddressInfo.get('postalCode')?.value,
                        country: countryObject || this.masjidAddressInfo.get('country')?.value,
                        latitude: result.lat.toString(),
                        longitude: result.lng.toString(),
                        formattedAddress: result.formattedAddress
                    }, { emitEvent: false }); // Prevent triggering the reset logic
                    
                    // Show map container first
                    this.showMap = true;
                    
                    // Wait for next render cycle before initializing map
                    await new Promise(resolve => setTimeout(resolve, 0));
                    
                    // Initialize or update map with new location
                    await this.initializeMap({
                        lat: result.lat,
                        lng: result.lng
                    });
                    
                    this.isAddressLookupLoading = false;
                },
                error: (error) => {
                    console.error('Error looking up address:', error);
                    this.backendErrorSubject.next('Error looking up address. Please check the address details and try again.');
                    this.isAddressLookupLoading = false;
                    this.showMap = false;
                }
            });
    }

    onCountrySelected(event: MatAutocompleteSelectedEvent): void {
        CountryValidators.handleCountrySelection(
            event,
            this.masjidAddressInfo.get('country')!,
            this
        );
    }

    doChangeStep(direction: StepDirection) {
        if (!this.masjidAddressInfo.valid && direction === 'next') {
            FormValidators.validateAllFormFields(this.masjidAddressInfo);
            return;
        }

        // Don't allow proceeding if address hasn't been confirmed
        if (direction === StepDirection.Next && !this.masjidAddressInfo.get('formattedAddress')?.value) {
            this.backendErrorSubject.next('Please lookup and confirm the address before proceeding.');
            return;
        }

        this.changeStep.emit({
            currentStep: Steps.AddressInfo,
            direction: direction,
        });
    }
}
