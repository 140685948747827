<div class="flex-center">
  <mat-card class="masjid-card" (click)="onViewDetails()">
    <mat-card-content>
      <div class="container-image">
        <img
          [src]="masjid.ImagesIds.length ? getMasjidImageSrc(masjid.ImagesIds[0]) : getMasjidImageSrc()"
          [alt]="masjid.name"
        />
      </div>
      <div class="masjid-info">
        <div class="masjid-name">{{ masjid.name }}</div>
        <div class="masjid-address" [class.missing-address]="!masjid.formattedAddress">
          {{ masjid.formattedAddress || ('masjidData.addressNotRegistered' | transloco) }}
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
