import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {

    constructor(private translocoService: TranslocoService) {
        super();
        this.updateTranslations();

        this.translocoService.langChanges$
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.updateTranslations();
            });
    }

    updateTranslations() {
        this.itemsPerPageLabel = this.translocoService.translate('paginator.itemsPerPage');
        this.nextPageLabel = this.translocoService.translate('paginator.nextPage');
        this.previousPageLabel = this.translocoService.translate('paginator.previousPage');
        this.firstPageLabel = this.translocoService.translate('paginator.firstPage');
        this.lastPageLabel = this.translocoService.translate('paginator.lastPage');

        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
            if (length === 0 || pageSize === 0) {
                return `0 ${this.translocoService.translate('paginator.pageOf')} ${length}`;
            }

            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            const endIndex = startIndex < length ? 
                Math.min(startIndex + pageSize, length) : 
                startIndex + pageSize;

            return `${startIndex + 1} - ${endIndex} ${this.translocoService.translate('paginator.pageOf')} ${length}`;
        };

        this.changes.next();
    }
} 