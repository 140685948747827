<ng-container *ngIf="isFormInitialized">
  <ng-container [ngSwitch]="currentStep$ | async" [formGroup]="masjidForm">
    <app-basic-information
      *ngSwitchCase="'basicInfo'"
      (changeStep)="changeStep($event)"
      formGroupName="basicInfo"
      [isUserLoggedIn]="isUserLoggedIn"
      [bucketId]="masjidBucketId"
      (masjidImages)="setMasjidImages($event)"
      [initFiles]="masjidImages"
    ></app-basic-information>
    <app-masjid-address
      *ngSwitchCase="'addressInfo'"
      (changeStep)="changeStep($event)"
      formGroupName="addressInfo"
      [countries]="countries"
    ></app-masjid-address>
    <app-masjid-contacts
      *ngSwitchCase="'contactInfo'"
      (changeStep)="changeStep($event)"
      formGroupName="contactInfo"
    ></app-masjid-contacts>
    <app-masjid-leader
      *ngSwitchCase="'leaderInfo'"
      (changeStep)="changeStep($event)"
      formGroupName="leaderInfo"
      [bucketId]="leaderBucketId"
      (masjidLeaderImage)="setMasjidLeaderImage($event)"
      [initFiles]="masjidImages"
    >
    </app-masjid-leader>
    <app-masjid-organization
      *ngSwitchCase="'NGOInfo'"
      (changeStep)="changeStep($event)"
      formGroupName="NGOInfo"
    >
    </app-masjid-organization>
    <app-masjid-lessons
      *ngSwitchCase="'lessonsInfo'"
      (changeStep)="changeStep($event)"
      formGroupName="lessonsInfo"
      (lessons)="setMasjidLessons($event)"
    ></app-masjid-lessons>
    <app-masjid-admins
      *ngSwitchCase="'adminsInfo'"
      (changeStep)="changeStep($event)"
      formGroupName="adminsInfo"
      [isSubmittingSave$]="isSubmittingSave$"
      (adminsSelected)="setSelectedAdmins($event)"
    ></app-masjid-admins>
  </ng-container>
</ng-container>

<div *ngIf="!isFormInitialized" class="loading-container">
  <mat-spinner></mat-spinner>
  <p>{{ 'masjidData.loading' | transloco }}</p>
</div>
