import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormControl } from '@angular/forms';

export class FormValidators {
    static allFieldsTouched(control: AbstractControl): void {
        if (control instanceof FormGroup) {
            Object.values(control.controls).forEach(ctrl => {
                ctrl.markAsTouched();
                ctrl.markAsDirty();
                
                if (ctrl instanceof FormGroup) {
                    this.allFieldsTouched(ctrl);
                }
            });
        }
    }

    static atLeastOneRequired(fields: string[]): ValidatorFn {
        return (group: AbstractControl): ValidationErrors | null => {
            const hasValue = fields.some(field => {
                const control = group.get(field);
                return control && control.value;
            });
            
            return hasValue ? null : { atLeastOneRequired: true };
        };
    }

    static validateAllFormFields(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field)
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true })
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control)
            }
        })
    }

    static isEmptyOrWhiteSpace(str: string): boolean {
        return str.trim().length === 0
    }

    static isEmptyObject(obj: object): boolean {
        if (typeof obj !== 'object' || obj === null) {
            return false
        }

        if (Object.getPrototypeOf(obj) === Object.prototype) {
            // Empty object (no inherited properties)
            return true
        }

        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                // Object has at least one own property
                return false
            }
        }

        return true
    }
}