import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ArrayValidators {
    static minArrayLength(minimum: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!Array.isArray(control.value)) return null;
            
            return control.value.length < minimum ? 
                { minArrayLength: { required: minimum, actual: control.value.length } } : 
                null;
        };
    }

    static atLeastOneSelected(): ValidatorFn {
        return this.minArrayLength(1);
    }
} 