<ng-container *transloco="let t; read: 'masjidData'">
  <form
    class="masjid-edit-form"
    [formGroup]="masjidForm"
    (ngSubmit)="updateMasjid()"
  >
    <mat-card class="basic-info-card">
      <mat-card-header class="flex-center">
        <mat-card-title>
          <h1 class="text-2xl sm:text-3xl">{{ t('basicInformation') }}</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row" style="margin-top: 1.5rem">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('nameLabel') }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ t('nameLabel') }}"
                formControlName="name"
              />
              <mat-error
                *ngIf="masjidForm.controls['name'].hasError('required')"
              >
                {{ t('nameLabel') + ' ' + t('errorIsRequired') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('descriptionLabel') }}</mat-label>
              <textarea
                matInput
                matTextareaAutosize
                placeholder="{{ t('descriptionLabel') }}"
                formControlName="description"
                class="autosize-textarea"
              >
              </textarea>
              <mat-error
                *ngIf="masjidForm.get('description')?.hasError('minLength')"
              >
                {{ t('descriptionLabel') + ' ' + t('errorMinValue') }}
              </mat-error>
              <mat-error
                *ngIf="masjidForm.get('description')?.hasError('maxLength')"
              >
                {{ t('descriptionMaxLength') }}
              </mat-error>
              <mat-error
                *ngIf="masjidForm.get('description')?.hasError('required')"
              >
                {{ t('descriptionLabel') + ' ' + t('errorIsRequired') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('emailLabel') }}</mat-label>
              <input
                matInput
                type="email"
                placeholder="{{ t('emailLabel') }}"
                formControlName="email"
              />
              <mat-error
                *ngIf="masjidForm.controls['email'].hasError('pattern')"
              >
                {{ t('emailLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label for="openHour">{{ t('openHourLabel') }}</mat-label>
              <mat-select
                formControlName="openHour"
                [value]="selectedOpenHour"
                matNativeControl
                id="openHour"
              >
                <mat-option *ngFor="let hour of openHour" [value]="hour">
                  {{ hour }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="masjidForm.controls['openHour'].hasError('timeInvalid')"
              >
                {{ t('openHourLabel') + ' ' + t('timeInvalid') }}
              </mat-error>
              <mat-error
                *ngIf="
                  masjidForm.controls['openHour'].invalid &&
                  masjidForm.controls['openHour'].hasError('required')
                "
              >
                {{ t('closeHourLabel') + ' ' + t('errorIsRequired') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('closeHourLabel') }}</mat-label>
              <mat-select
                formControlName="closeHour"
                [value]="selectedCloseHour"
                matNativeControl
              >
                <mat-option *ngFor="let hour of closeHour" [value]="hour">
                  {{ hour }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="masjidForm.controls['closeHour'].hasError('timeInvalid')"
              >
                {{ t('closeHourLabel') + ' ' + t('timeInvalid') }}
              </mat-error>
              <mat-error
                *ngIf="
                  masjidForm.controls['closeHour'].invalid &&
                  masjidForm.controls['closeHour'].hasError('required')
                "
              >
                {{ t('closeHourLabel') + ' ' + t('errorIsRequired') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" style="margin-top: 1.5rem">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('masjidFacilities') }}</mat-label>
              <mat-select formControlName="masjidFacilities" multiple>
                <mat-option
                  *ngFor="let facility of availableFacilities"
                  [value]="facility"
                >
                  {{ t('facilities.' + facility) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="masjid-edit-images">
      <mat-card-header class="flex-center">
        <mat-card-title>
          <h1 class="text-2xl sm:text-3xl">{{ t('masjidImages') }}</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div
          class="image-list"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
          [cdkDropListOrientation]="
            (isHandset$ | async) ? 'vertical' : 'horizontal'
          "
          (dragover)="onDragOver($event)"
          (drop)="onDrop($event)"
        >
          <ng-container *ngIf="images.length > 0; else noImages">
            <!-- Display images if available -->
            <div
              class="image-item"
              *ngFor="let item of images; let i = index"
              cdkDrag
            >
              <!-- Add your image here -->
              <img src="{{ item.dataUrl }}" alt="Image {{ i }}" />
              <button
                id="dragdropImageBtn"
                type="button"
                mat-icon-button
                (click)="deleteImage(i, item)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </ng-container>
          <ng-template #noImages>
            <!-- Display a message if no images are available -->
            <p>
              No images available for this masjid.<br />To add new images please
              drag and drop here.
            </p>
          </ng-template>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="address-info-card">
      <mat-card-header class="flex-center">
        <mat-card-title>
          <h1 class="text-2xl sm:text-3xl">{{ t('addressInformation') }}</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row" style="margin-top: 1.5rem">
          <div class="col" style="margin-inline: 0">
            <div class="row">
              <div class="col">
                <mat-form-field
                  appearance="outline"
                  floatLabel="always"
                  class="full-width"
                >
                  <mat-label>{{ t('streetLabel') }}</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="{{ t('streetLabel') }}"
                    formControlName="street"
                  />
                  <mat-error
                    *ngIf="masjidForm.controls['street'].hasError('pattern')"
                  >
                    {{ t('streetLabel') + ' ' + t('errorIsInvalid') }}
                  </mat-error>
                  <mat-error
                    *ngIf="masjidForm.controls['street'].hasError('required')"
                  >
                    {{ t('streetLabel') + ' ' + t('errorIsRequired') }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field
                  appearance="outline"
                  floatLabel="always"
                  class="full-width"
                >
                  <mat-label>{{ t('houseNumberLabel') }}</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="{{ t('houseNumberLabel') }}"
                    formControlName="houseNumber"
                  />
                  <mat-error
                    *ngIf="
                      masjidForm.controls['houseNumber'].hasError('pattern')
                    "
                  >
                    {{ t('houseNumberLabel') + ' ' + t('errorIsInvalid') }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      masjidForm.controls['houseNumber'].hasError('required')
                    "
                  >
                    {{ t('houseNumberLabel') + ' ' + t('errorIsRequired') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <mat-form-field
                  appearance="outline"
                  floatLabel="always"
                  class="full-width"
                >
                  <mat-label>{{ t('cityLabel') }}</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="{{ t('cityLabel') }}"
                    formControlName="city"
                  />
                  <mat-error
                    *ngIf="masjidForm.controls['city'].hasError('pattern')"
                  >
                    {{ t('cityLabel') + ' ' + t('errorIsInvalid') }}
                  </mat-error>
                  <mat-error
                    *ngIf="masjidForm.controls['city'].hasError('required')"
                  >
                    {{ t('cityLabel') + ' ' + t('errorIsRequired') }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field
                  appearance="outline"
                  floatLabel="always"
                  class="full-width"
                >
                  <mat-label>{{ t('stateLabel') }}</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="{{ t('stateLabel') }}"
                    formControlName="state"
                  />
                  <mat-error
                    *ngIf="masjidForm.controls['state'].hasError('pattern')"
                  >
                    {{ t('stateLabel') + ' ' + t('errorIsInvalid') }}
                  </mat-error>
                  <mat-error
                    *ngIf="masjidForm.controls['state'].hasError('required')"
                  >
                    {{ t('stateLabel') + ' ' + t('errorIsRequired') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <mat-form-field
                  appearance="outline"
                  floatLabel="always"
                  class="full-width"
                >
                  <mat-label>{{ t('countryLabel') }}</mat-label>
                  <input
                    type="text"
                    placeholder="{{ t('countryLabel') }}"
                    matInput
                    formControlName="country"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete
                    autoActiveFirstOption
                    [displayWith]="displayFn"
                    (optionSelected)="onCountrySelected($event)"
                    #auto="matAutocomplete"
                  >
                    <mat-option
                      *ngFor="let country of filteredCountries$ | async"
                      [value]="country"
                    >
                      <img
                        [src]="country.flag"
                        [alt]="country.name"
                        class="country-flag"
                      />
                      <span>{{ country.name }}</span>
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error
                    *ngIf="masjidForm.controls['country'].errors?.['countryValidator']?.error"
                  >
                    {{ masjidForm.controls['country'].errors?.['countryValidator']?.error | transloco }}
                  </mat-error>
                  <mat-error
                    *ngIf="masjidForm.controls['country'].hasError('required') && !masjidForm.controls['country'].errors?.['countryValidator']"
                  >
                    {{ 'validations.country.countryRequired' | transloco }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field
                  appearance="outline"
                  floatLabel="always"
                  class="full-width"
                >
                  <mat-label>{{ t('postalCodeLabel') }}</mat-label>
                  <input
                    matInput
                    #postalCode
                    placeholder="{{ t('postalCodeLabel') }}"
                    type="text"
                    formControlName="postalCode"
                  />
                  <mat-error
                    *ngIf="
                      masjidForm.controls['postalCode'].hasError('pattern')
                    "
                  >
                    {{ t('postalCodeLabel') + ' ' + t('errorIsInvalid') }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      masjidForm.controls['postalCode'].hasError('required')
                    "
                  >
                    {{ t('postalCodeLabel') + ' ' + t('errorIsRequired') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="contact-info-card">
      <mat-card-header class="flex-center">
        <mat-card-title>
          <h1 class="text-2xl sm:text-3xl">{{ t('contactInformation') }}</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row" style="margin-top: 1.5rem">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('telephoneLabel') }}</mat-label>
              <input
                matInput
                type="tel"
                placeholder="{{ t('telephoneLabel') }}"
                formControlName="telephone"
              />
              <mat-error
                *ngIf="masjidForm.controls['telephone'].hasError('pattern')"
              >
                {{ t('telephoneLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('mobileLabel') }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ t('mobileLabel') }}"
                formControlName="mobile"
              />
              <mat-error
                *ngIf="masjidForm.controls['mobile'].hasError('pattern')"
              >
                {{ t('mobileLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('whatsappLabel') }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ t('whatsappLabel') }}"
                formControlName="whatsapp"
              />
              <mat-error
                *ngIf="masjidForm.controls['whatsapp'].hasError('pattern')"
              >
                {{ t('whatsappLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-card-title>{{ t('socialMedia') }}</mat-card-title>
          </div>
        </div>
        <div class="row" style="margin-top: 1.5rem">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('facebookLabel') }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ t('facebookLabel') }}"
                formControlName="facebookUrl"
              />
              <mat-error
                *ngIf="masjidForm.controls['facebookUrl'].hasError('pattern')"
              >
                {{ t('facebookLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('instagramLabel') }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ t('instagramLabel') }}"
                formControlName="instagramUrl"
              />
              <mat-error
                *ngIf="masjidForm.controls['instagramUrl'].hasError('pattern')"
              >
                {{ t('instagramLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="leader-info-card">
      <mat-card-header class="flex-center">
        <mat-card-title>
          <h1 class="text-2xl sm:text-3xl">{{ t('leaderInformation') }}</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row" style="margin-top: 1.5rem">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('leaderNameLabel') }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ t('leaderNameLabel') }}"
                formControlName="leaderName"
              />
              <mat-error
                *ngIf="masjidForm.controls['leaderName'].hasError('pattern')"
              >
                {{ t('leaderNameLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('leaderDescriptionLabel') }}</mat-label>
              <textarea
                matInput
                type="text"
                placeholder="{{ t('leaderDescriptionLabel') }}"
                formControlName="leaderDescription"
              ></textarea>

              <mat-error
                *ngIf="
                  masjidForm.controls['leaderDescription'].hasError('pattern')
                "
              >
                {{ t('leaderDescriptionLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <app-photo-upload
      [bucketId]="leaderBucketId"
      [multipleFileUpload]="false"
      [fileLimit]="1"
      [title]="'Masjid leader photo'"
      [isRequired]="false"
      [parentForm]="masjidForm"
      (sendPhotosToParentForm)="getImagesFromChildForm($event)"
      [initFiles]="leaderImage"
    ></app-photo-upload>
    <mat-card class="organization-info-card">
      <mat-card-header class="flex-center">
        <mat-card-title>
          <h1 class="text-2xl sm:text-3xl">{{ t('NgoInformation') }}</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row" style="margin-top: 1.5rem">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('NgoNameLabel') }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ t('NgoNameLabel') }}"
                formControlName="NgoName"
              />
              <mat-error
                *ngIf="masjidForm.controls['NgoName'].hasError('pattern')"
              >
                {{ t('NgoNameLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('NgoTelephoneLabel') }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ t('NgoTelephoneLabel') }}"
                formControlName="NgoTelephone"
              />
              <mat-error
                *ngIf="masjidForm.controls['NgoTelephone'].hasError('pattern')"
              >
                {{ t('NgoTelephoneLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('NgoMobileLabel') }}</mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ t('NgoMobileLabel') }}"
                formControlName="NgoMobile"
              />
              <mat-error
                *ngIf="masjidForm.controls['NgoMobile'].hasError('pattern')"
              >
                {{ t('NgoMobileLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field
              appearance="outline"
              floatLabel="always"
              class="full-width"
            >
              <mat-label>{{ t('NgoEmailLabel') }}</mat-label>
              <input
                matInput
                type="email"
                placeholder="{{ t('NgoEmailLabel') }}"
                formControlName="NgoEmail"
              />
              <mat-error
                *ngIf="masjidForm.controls['NgoEmail'].hasError('pattern')"
              >
                {{ t('NgoEmailLabel') + ' ' + t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- Admin Section - Only visible to owner -->
    <mat-card class="admins-info-card" *ngIf="isOwner">
      <mat-card-header class="flex-center">
        <mat-card-title>
          <h1 class="text-2xl sm:text-3xl">{{ t('addAdministrators') }}</h1>
        </mat-card-title>
        <mat-card-subtitle>
          {{ t('addAdministratorsDescription') }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="admin-input-container">
          <mat-form-field appearance="outline" class="admin-input">
            <mat-label>{{ t('administratorEmail') }}</mat-label>
            <input matInput #adminEmail type="email" placeholder="{{ t('administratorEmail') }}">
          </mat-form-field>
          <button mat-raised-button color="primary" 
                  [disabled]="isLoading || !adminEmail.value"
                  (click)="onAddAdmin(adminEmail.value)"
                  type="button">
            <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
            <span *ngIf="!isLoading">{{ t('addAdmin') }}</span>
          </button>
        </div>

        <div class="notification-message" *ngIf="errorMessage" 
             [ngClass]="{'success': errorMessage === 'adminAddSuccess' || errorMessage === 'adminRemoveSuccess',
                        'error': errorMessage === 'adminEmailExists' || errorMessage === 'adminNotFound' || 
                                errorMessage === 'userNotRegistered' || errorMessage === 'cannotAddOwnerAsAdmin'}">
          {{ t(errorMessage) }}
        </div>

        <div class="selected-admins" *ngIf="selectedAdmins.length > 0">
          <h3>{{ t('selectedAdministrators') }}:</h3>
          <div class="admin-list">
            <div class="admin-item" *ngFor="let admin of selectedAdmins">
              <div class="admin-info">
                <mat-icon class="admin-icon">person</mat-icon>
                <div class="admin-details">
                  <span class="admin-name">{{ admin.firstName }} {{ admin.lastName }}</span>
                  <span class="admin-email">{{ admin.email }}</span>
                </div>
              </div>
              <button type="button" mat-icon-button color="warn" (click)="removeAdmin(admin)" aria-label="Remove admin">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <ng-container [formGroup]="masjidLessonsInfo">
      <mat-card
        class="lessons-info-card"
        *transloco="let t; read: 'masjidData'"
      >
        <mat-card-header class="flex-center">
          <mat-card-title>
            <h1 class="text-2xl sm:text-3xl">{{ t('lessonInformation') }}</h1>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row" style="margin-top: 1.5rem">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('lessonNameLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ t('lessonNameLabel') }}"
                  formControlName="lessonName"
                />
                <mat-error
                  *ngIf="
                    masjidLessonsInfo.controls['lessonName'].hasError('pattern')
                  "
                >
                  {{ t('lessonNameLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('lessonLanguageLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ t('lessonLanguageLabel') }}"
                  formControlName="lessonLanguage"
                />

                <mat-error
                  *ngIf="
                    masjidLessonsInfo.controls['lessonLanguage'].hasError(
                      'pattern'
                    )
                  "
                >
                  {{ t('lessonLanguageLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('lessonDurationLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="lessonDuration"
                  readonly
                />

                <mat-error
                  *ngIf="
                    masjidLessonsInfo.controls['lessonDuration'].hasError(
                      'pattern'
                    )
                  "
                >
                  {{ t('lessonDurationLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('lessonStartLabel') }}</mat-label>
                <input
                  matInput
                  [ngxTimepicker]="lessonStart"
                  [format]="24"
                  [ngxMaterialTimepickerTheme]="timepickerTheme"
                  formControlName="lessonStart"
                  readonly
                />
                <mat-icon matSuffix (click)="lessonStart.open()"
                  >schedule</mat-icon
                >
                <ngx-material-timepicker
                  #lessonStart
                  (timeSet)="updateDuration($event, 'start')"
                ></ngx-material-timepicker>
                <mat-error
                  *ngIf="
                    masjidLessonsInfo.controls['lessonStart'].hasError(
                      'pattern'
                    )
                  "
                >
                  {{ t('lessonStartLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('lessonEndLabel') }}</mat-label>
                <input
                  matInput
                  [ngxTimepicker]="lessonEnd"
                  [format]="24"
                  [ngxMaterialTimepickerTheme]="timepickerTheme"
                  formControlName="lessonEnd"
                  readonly
                />
                <mat-icon matSuffix (click)="lessonEnd.open()"
                  >schedule</mat-icon
                >
                <ngx-material-timepicker
                  #lessonEnd
                  (timeSet)="updateDuration($event, 'end')"
                ></ngx-material-timepicker>
                <mat-error
                  *ngIf="
                    masjidLessonsInfo.controls['lessonEnd'].hasError('pattern')
                  "
                >
                  {{ t('lessonEndLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('lessonInstructorLabel') }}</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="{{ t('lessonInstructorLabel') }}"
                  formControlName="lessonInstructor"
                />

                <mat-error
                  *ngIf="
                    masjidLessonsInfo.controls['lessonInstructor'].hasError(
                      'pattern'
                    )
                  "
                >
                  {{ t('lessonInstructorLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="full-width"
              >
                <mat-label>{{ t('lessonDaysLabel') }}</mat-label>
                <mat-select formControlName="lessonDays" multiple>
                  <mat-option
                    *ngFor="let day of t('days', { array: true })"
                    [value]="day"
                  >
                    {{ day }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="
                    masjidLessonsInfo.controls['lessonDays'].hasError(
                      'atLeastNDays'
                    )
                  "
                >
                  {{ t('lessonDaysLabel') + ' ' + t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <input type="hidden" formControlName="lessonId" />
          <input type="hidden" formControlName="masjid_id" />
        </mat-card-content>
        <mat-card-actions
          class="flex-center full-width"
          style="padding-top: 1.5rem; padding-bottom: 1.5rem"
        >
          <div class="row">
            <div class="col flex-center">
              <button mat-raised-button (click)="addToTable()" type="button">
                Add to Table
              </button>
              <button mat-raised-button (click)="clearTable()" type="button">
                Clear table
              </button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card>
    </ng-container>

    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      *transloco="let t; read: 'masjidData'"
    >
      <ng-content></ng-content>
      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ t('LessonsTableColumnsNames.name') }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- language Column -->
      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ t('LessonsTableColumnsNames.language') }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.language }}</td>
      </ng-container>

      <!-- Color Column -->
      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('LessonsTableColumnsNames.duration') }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.duration }}</td>
      </ng-container>

      <!-- Start Column -->
      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('LessonsTableColumnsNames.start') }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.start }}</td>
      </ng-container>

      <!-- End Column -->
      <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('LessonsTableColumnsNames.end') }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.end }}</td>
      </ng-container>

      <!-- Instructor Column -->
      <ng-container matColumnDef="instructor">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('LessonsTableColumnsNames.instructor') }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.instructor }}</td>
      </ng-container>

      <!-- Days Column -->
      <ng-container matColumnDef="days">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('LessonsTableColumnsNames.days') }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.days.join(', ') }}
        </td>
      </ng-container>

      <!-- Delete Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('LessonsTableColumnsNames.actions') }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="row">
            <div class="col flex-center">
              <button
                mat-icon-button
                color="primary"
                (click)="editItem(row)"
                type="button"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                color="warn"
                (click)="removeItem(row)"
                type="button"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Custom row definitions to be provided to the wrapper table. -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data</td>
      </tr>
    </table>

    <mat-card>
      <mat-card-actions>
        <div
          class="row full-width"
          style="margin-top: 1.5rem; margin-bottom: 1.5rem"
        >
          <div class="col flex-center">
            <button
              type="button"
              mat-raised-button
              color="warn"
              routerLink="/home"
            >
              {{ t('cancel') }}
            </button>
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="isSubmittingSave$ | async"
              style="margin-left: 1.5rem"
            >
              {{ t('save') }}
            </button>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</ng-container>
