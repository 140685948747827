import { Injectable } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ComponentStore } from '@ngrx/component-store'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import {
    EditMasjidStateInterface,
    PhotoOperation,
    initialEditMasjidState,
} from 'src/app/masjids/interfaces/masjid.state.interface'
import { MasjidLessonsInterface } from '../../interfaces/masjidData.interface'
import {
    resetFiles,
    setDeleteFiles,
    setIsSubmittingSave,
    setLessons,
    setMasjidData,
    setUploadFiles,
} from '../actions/edit.masjid.actions'

@UntilDestroy({ checkProperties: true })
@Injectable({ providedIn: 'root' })
export class EditMasjidStore extends ComponentStore<EditMasjidStateInterface> {
    constructor(private _store: Store) {
        super(initialEditMasjidState)
    }
    readonly getMasjidData = this.select((state) => state.masjidData)

    readonly isSubmittingSave$ = this.select((state) => state.isSubmittingSave)

    public readonly setIsSubmittingSave = this.updater(
        (state, save: boolean) => {
            this._store.dispatch(
                setIsSubmittingSave({
                    save: save,
                })
            )
            return { ...state, isSubmittingSave: save }
        }
    )

    public readonly setMasjidData = this.updater(
        (state, data: { [key: string]: any }) => {
            this._store.dispatch(
                setMasjidData({
                    data: data,
                })
            )
            return {
                ...state,
                masjidData: {
                    ...state.masjidData,
                    ...data,
                },
            }
        }
    )

    public readonly setDeleteFiles = this.updater(
        (state, files: Observable<PhotoOperation>[]) => {
            this._store.dispatch(
                setDeleteFiles({
                    files: files,
                })
            )
            return { ...state, deleteFiles: files }
        }
    )

    public readonly setUploadFiles = this.updater(
        (state, files: Observable<PhotoOperation>[]) => {
            this._store.dispatch(
                setUploadFiles({
                    files: files,
                })
            )
            return { ...state, uploadFiles: files }
        }
    )

    public readonly setLessons = this.updater(
        (
            state,
            {
                newLessons,
                updateLessons,
                deleteLessons,
            }: {
                newLessons: MasjidLessonsInterface[]
                updateLessons: MasjidLessonsInterface[]
                deleteLessons: MasjidLessonsInterface[]
            }
        ) => {
            this._store.dispatch(
                setLessons({
                    newLessons: newLessons,
                    deleteLessons: deleteLessons,
                    updateLessons: updateLessons,
                })
            )

            return {
                ...state,
                newLessons: newLessons,
                deleteLessons: deleteLessons,
                updateLessons: updateLessons,
            }
        }
    )

    public readonly resetFiles = this.updater((state) => {
        this._store.dispatch(resetFiles())
        return { ...state, deleteFiles: [], uploadFiles: [] }
    })

    public readonly resetState = this.updater(() => initialEditMasjidState)
}
