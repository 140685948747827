import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ComponentStore } from '@ngrx/component-store'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppwriteException } from 'appwrite'
import {
    Observable,
    catchError,
    map,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import { LogService } from 'src/app/shared/services/log.service'
import { MasjidDocumentService } from 'src/app/shared/services/masjid.service'
import Utils from 'src/app/shared/services/utils'
import {
    MasjidStateInterface,
    initialMasjidState,
} from '../../interfaces/masjid.state.interface'
import { MasjidDataInterface } from '../../interfaces/masjidData.interface'
import {
    loadMasjidList,
    loadMasjidListFailure,
    loadMasjidListSuccess,
    viewMasjidDetailsAndNavigate,
} from '../actions/masjid.actions'

@Injectable({ providedIn: 'root' })
export class MasjidStore extends ComponentStore<MasjidStateInterface> {
    constructor(
        private actions$: Actions,
        private _store: Store,
        private _masjidService: MasjidDocumentService,
        private _logService: LogService,
        private _router: Router
    ) {
        super(initialMasjidState)
    }

    public readonly setMasjidList = this.updater(
        (state, data: MasjidDataInterface[]) => ({
            ...state,
            masjidsList: data,
            loading: false
        })
    )

    public readonly setLoading = this.updater((state, loading: boolean) => ({
        ...state,
        loading: loading
    }))

    public readonly setMasjid = this.updater(
        (state, data: MasjidDataInterface) => ({
            ...state,
            masjid: data,
            loading: false
        })
    )

    public readonly resetState = this.updater(() => initialMasjidState)

    public readonly getMasjidList = this.select((state) => state.masjidsList)

    public readonly getMasjid = this.select((state) => state.masjid)

    viewMasjidDetailsAndNavigate$ = this.effect(() =>
        this.actions$.pipe(
            ofType(viewMasjidDetailsAndNavigate),
            tap((action) => {
                const masjidId = action.masjidId
                this._router.navigate(['masjid', 'details', masjidId])
            })
        )
    )

    loadMasjidList$ = this.effect(() =>
        this.actions$.pipe(
            ofType(loadMasjidList),
            tap((action) => {
                this.setLoading(true)
            }),
            switchMap((action) =>
                this._masjidService.getMasjidList(action.page, action.pageSize).pipe(
                    map((response) => {
                        return this._store.dispatch(
                            loadMasjidListSuccess({ 
                                masjids: response.masjids,
                                total: response.total 
                            })
                        )
                    })
                )
            ),
            catchError((error) => {
                this.handleErrors(error)
                this.setLoading(false)
                return of(loadMasjidListFailure({ error: error }))
            })
        )
    )

    loadMasjidListSuccess$ = this.effect(() =>
        this.actions$.pipe(
            ofType(loadMasjidListSuccess),
            tap((action) => {
                this.setMasjidList(action.masjids)
                this.updatePagination(action.total)
                this.setLoading(false)
                return of(action.masjids)
            })
        )
    )

    loadMasjidListFailure$ = this.effect(() =>
        this.actions$.pipe(
            ofType(loadMasjidListFailure),
            tap((action) => {
                this.handleErrors(action.error)
                this.setLoading(false)
            })
        )
    )

    private updatePagination(total: number) {
        this.updater((state) => ({
            ...state,
            pagination: {
                ...state.pagination,
                totalItems: total
            }
        }))
    }

    handleErrors(error: Error | AppwriteException): Observable<never> {
        let message: string
        if (error instanceof AppwriteException) {
            message = Utils.formatAppWriteException(error)
        } else message = Utils.formatException(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        return throwError(() => error)
    }
}
