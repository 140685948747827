import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsLoaderService {
  private static promise: Promise<void>;
  private static isLoading = false;
  private static loadError: Error | null = null;

  public load(): Promise<void> {
    if (GoogleMapsLoaderService.loadError) {
      return Promise.reject(GoogleMapsLoaderService.loadError);
    }

    if (!GoogleMapsLoaderService.promise) {
      GoogleMapsLoaderService.isLoading = true;
      GoogleMapsLoaderService.promise = new Promise<void>((resolve, reject) => {
        try {
          if (typeof google === 'undefined' || !google.maps) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.google_maps_api_key}&libraries=places`;
            
            script.onload = () => {
              GoogleMapsLoaderService.isLoading = false;
              if (typeof google === 'undefined' || !google.maps) {
                const error = new Error('Google Maps API failed to load');
                GoogleMapsLoaderService.loadError = error;
                reject(error);
              } else {
                resolve();
              }
            };

            script.onerror = (error) => {
              GoogleMapsLoaderService.isLoading = false;
              const loadError = new Error('Failed to load Google Maps API script');
              GoogleMapsLoaderService.loadError = loadError;
              reject(loadError);
            };

            document.body.appendChild(script);
          } else {
            resolve();
          }
        } catch (error) {
          GoogleMapsLoaderService.isLoading = false;
          GoogleMapsLoaderService.loadError = error as Error;
          reject(error);
        }
      });
    }
    return GoogleMapsLoaderService.promise;
  }

  public isLoaded(): boolean {
    return typeof google !== 'undefined' && !!google.maps && !GoogleMapsLoaderService.isLoading;
  }

  public hasError(): boolean {
    return !!GoogleMapsLoaderService.loadError;
  }

  public getError(): Error | null {
    return GoogleMapsLoaderService.loadError;
  }
} 