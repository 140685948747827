<mat-card class="masjid-details-card" *transloco="let t">
  <mat-card-content *ngIf="data">
    <!-- Main Photo Section -->
    <div class="row" style="margin-top: 1.5rem">
      <div class="mainMasjidPhoto" *ngIf="photosUrl.length > 0">
        <div class="image-container">
          <img src="{{ photosUrl[0] }}" alt="{{ data.name }}" class="image" />
          <div class="image-name" color="warn">{{ data.name }}</div>
        </div>
      </div>
    </div>

    <!-- Description Section -->
    <div class="row" style="margin-top: 1.5rem">
      <h2>{{ t('masjidData.descriptionLabel') }}</h2>
    </div>
    <div
      class="row-masjidInfo"
      style="margin-top: 1.5rem; margin-bottom: 1.5rem"
    >
      <div class="col">
        <p class="masjid-name">{{ data.name }}</p>
        <p *ngIf="data.description">{{ data.description }}</p>
      </div>
      <div class="col">
        <p>
          {{ t('masjidData.masjidTimingLabel') }} {{ data.openHour }} to
          {{ data.closeHour }}
        </p>
        <p>
          {{ t('masjidData.addressLabel') }} {{ data.formattedAddress }}
        </p>
        <p *ngIf="data.telephone">{{ t('masjidData.telephoneInfoLabel') }} {{ data.telephone }}</p>
        <p *ngIf="data.email">{{ t('masjidData.emailInfoLabel') }} {{ data.email }}</p>

        <mat-icon
          class="social-icon"
          *ngIf="data.instagramUrl"
          (click)="openInstagram()"
          >instagram</mat-icon
        >
        <mat-icon
          class="social-icon"
          *ngIf="data.facebookUrl"
          (click)="openFacebook()"
          >facebook</mat-icon
        >
        <mat-icon
          class="social-icon"
          *ngIf="data.whatsapp"
          (click)="openWhatsApp()"
          >whatsapp</mat-icon
        >
      </div>
    </div>

    <!-- Map Button and Container -->
    <div class="map-section">
      <button
        mat-raised-button
        color="primary"
        (click)="toggleMap()"
        [disabled]="!hasValidCoordinates()"
        [matTooltip]="getMapButtonTooltip()"
        class="map-button"
      >
        <mat-icon>{{ showMap ? 'map_off' : 'map' }}</mat-icon>
        {{ showMap ? t('masjidData.hideMap') : t('masjidData.showOnMap') }}
      </button>

      <div *ngIf="!hasValidCoordinates()" class="location-warning">
        {{ t('masjidData.noLocationCoordinates') }}
      </div>

      <div *ngIf="isMapLoading" class="map-loading">
        <mat-icon class="spin">sync</mat-icon>
        {{ t('masjidData.loadingMap') }}
      </div>

      <div *ngIf="showMap" class="map-container">
        <h3>{{ t('masjidData.masjidLocation') }}</h3>
        <div #mapContainer id="map"></div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Masjid Photos Section -->
    <div class="row" style="margin-top: 1.5rem">
      <h2>{{ t('masjidData.masjidPhotosSection') }}</h2>
    </div>
    <div class="masjid-photos" *ngIf="photosUrl.length > 0">
      <mat-card *ngFor="let photo of photosUrl" class="photo-card">
        <img mat-card-image [src]="photo" alt="Masjid photo" />
      </mat-card>
    </div>

    <mat-divider></mat-divider>

    <!-- Leader Info Section -->
    <div class="row" style="margin-top: 1.5rem">
      <h2>{{ t('masjidData.masjidLeaderSection') }}</h2>
    </div>
    <div
      class="row-leader"
      *ngIf="
        leaderPhotoUrl || data.leaderName || data.leaderDescription;
        else noLeader
      "
    >
      <div class="image-container" *ngIf="leaderPhotoUrl">
        <img
          src="{{ leaderPhotoUrl }}"
          alt="{{ data.leaderName }}"
          width="100%"
        />
      </div>
      <div class="description-container" *ngIf="data.leaderName">
        <p class="leader-name">{{ data.leaderName }}</p>
        <p *ngIf="data.leaderDescription" class="image-description">
          {{ data.leaderDescription }}
        </p>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Activity Info Section -->
    <div class="row" style="margin-top: 1.5rem">
      <h2>{{ t('masjidData.masjidActivitySection') }}</h2>
    </div>
    <div *ngIf="!hasRegisteredLessons">
      <p>{{ t('masjidData.noRegisteredLessons') }}</p>
    </div>
    <div *ngIf="hasRegisteredLessons">
      <table
        mat-table
        [dataSource]="data.lessons"
        class="mat-elevation-z8 demo-table"
      >
        <!-- Lesson Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('masjidData.LessonsTableColumnsNames.name') }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Instructor Column (Missing Before) -->
        <ng-container matColumnDef="instructor">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('masjidData.LessonsTableColumnsNames.instructor') }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.instructor }}</td>
        </ng-container>

        <!-- Add more columns -->
        <ng-container matColumnDef="language">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('masjidData.LessonsTableColumnsNames.language') }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.language }}</td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('masjidData.LessonsTableColumnsNames.duration') }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.duration }}</td>
        </ng-container>

        <ng-container matColumnDef="start">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('masjidData.LessonsTableColumnsNames.start') }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.start }}</td>
        </ng-container>

        <ng-container matColumnDef="end">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('masjidData.LessonsTableColumnsNames.end') }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.end }}</td>
        </ng-container>

        <ng-container matColumnDef="days">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('masjidData.LessonsTableColumnsNames.days') }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.days.join(', ') }}
          </td>
        </ng-container>

        <!-- Table Headers & Rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <mat-divider></mat-divider>

    <!-- NGO Info Section -->
    <div class="row" style="margin-top: 1.5rem">
      <h2>{{ t('masjidData.masjidOrganizationSection') }}</h2>
    </div>
    <div
      class="row"
      *ngIf="
        data.NgoName || data.NgoTelephone || data.NgoMobile || data.NgoEmail;
        else noNgo
      "
    >
      <div class="col">
        <p *ngIf="data.NgoName">{{ t('masjidData.nameInfoLabel') }} {{ data.NgoName }}</p>
        <p *ngIf="data.NgoTelephone">{{ t('masjidData.telephoneInfoLabel') }} {{ data.NgoTelephone }}</p>
        <p *ngIf="data.NgoMobile">{{ t('masjidData.mobileInfoLabel') }} {{ data.NgoMobile }}</p>
        <p *ngIf="data.NgoEmail">{{ t('masjidData.emailInfoLabel') }} {{ data.NgoEmail }}</p>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Facilities Section -->
    <div class="row" style="margin-top: 1.5rem">
      <h2>{{ t('masjidData.masjidFacilities') }}</h2>
    </div>
    <div class="facilities-grid" *ngIf="data?.masjidFacilities && data.masjidFacilities.length > 0; else noFacilities">
      <mat-chip-set class="facilities-list">
        <mat-chip *ngFor="let facility of data.masjidFacilities">
          <mat-icon matChipLeading>{{ getFacilityIcon(facility) }}</mat-icon>
          {{ t('masjidData.facilities.' + facility) }}
        </mat-chip>
      </mat-chip-set>
    </div>

    <mat-divider></mat-divider>

    <!-- News Section -->
    <div class="row" style="margin-top: 1.5rem">
      <h2>{{ t('masjidData.newsListTitle') }}</h2>
    </div>
    <!-- masjid news Section -->
    <div *ngIf="latestNews.length > 0; else noNews">
      <div class="news-list">
        <mat-card
          *ngFor="let news of latestNews"
          class="news-card"
          (click)="viewNewsDetails(news.news_id!)"
          style="margin-top: 1.5rem"
        >
          <img mat-card-image [src]="news.imageUrl" alt="{{ news.title }}" />
          <mat-card-content>
            <h3>{{ news.title }}</h3>
            <p>{{ news.description }}</p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #noNews>
  <p>{{ 'masjidData.noNewsAvailable' | transloco }}</p>
</ng-template>
<ng-template #noNgo>
  <p>{{ 'masjidData.noRegisteredNgo' | transloco }}</p>
</ng-template>
<ng-template #noLeader>
  <p>{{ 'masjidData.noRegisteredLeader' | transloco }}</p>
</ng-template>
<ng-template #noFacilities>
  <p>{{ 'masjidData.noRegisteredFacilities' | transloco }}</p>
</ng-template>
