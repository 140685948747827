import { CommonModule } from '@angular/common'
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import {
    FormGroup,
    FormGroupDirective,
    ReactiveFormsModule,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import {
    MatError,
    MatFormFieldModule,
    MatLabel,
} from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslocoModule } from '@ngneat/transloco'
import { Observable, of } from 'rxjs'
import {
    StepDirection,
    StepEvent,
    Steps,
} from 'src/app/masjids/interfaces/masjid.form.steps.interface'
import utils from 'src/app/shared/services/utils'
import { FormValidators } from 'src/app/shared/validators/form.validator'

@Component({
    selector: 'app-masjid-organization',
    templateUrl: './masjid-organization.component.html',
    styleUrls: ['./masjid-organization.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        MatError,
        MatFormFieldModule,
        MatLabel,
        ReactiveFormsModule,
        CommonModule,
        TranslocoModule,
        MatButtonModule,
        MatInputModule,
    ],
})
export class MasjidOrganizationComponent implements OnInit {
    backendError$: Observable<string | null> = of(null)
    public StepDirection = StepDirection
    public masjidOrganizationInfo: FormGroup
    @Output() changeStep: EventEmitter<StepEvent> =
        new EventEmitter<StepEvent>()
    @Output() submit: EventEmitter<boolean> = new EventEmitter<boolean>()
    @Input() formGroupName: string

    ngOnInit(): void {
        this.masjidOrganizationInfo = this.rootFormGroup.control.get(
            this.formGroupName
        ) as FormGroup
    }
    doChangeStep(direction: StepDirection) {
        if (!this.masjidOrganizationInfo.valid && direction === 'next') {
            FormValidators.validateAllFormFields(this.masjidOrganizationInfo)
            return
        }

        this.changeStep.emit({
            currentStep: Steps.NGOInfo,
            direction: direction,
        })
    }
    constructor(private rootFormGroup: FormGroupDirective) {}
}
