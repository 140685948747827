<div class="manage-masjids" *transloco="let t">
  <h1>{{ t('manage_masjids.title') }}</h1>
  <ng-container *ngIf="masjids$ | async as masjids; else loading">
    <ng-container *ngIf="masjids && masjids.length > 0; else noMasjids">
      <div class="masjids-container">
        <div class="masjid-card" *ngFor="let masjid of masjids">
          <mat-card>
            <mat-card-header>
              <mat-card-title>{{ masjid.name }}</mat-card-title>
            </mat-card-header>
            
            <mat-card-content>
              <p>{{ masjid.description }}</p>
            </mat-card-content>

            <mat-card-actions>
              <button mat-raised-button color="primary" (click)="editMasjid(masjid.id)">
                <mat-icon>edit</mat-icon>
                {{ t('manage_masjids.edit_masjid') }}
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <!-- No Masjids Template -->
  <ng-template #noMasjids>
    <div class="empty-state">
      <mat-icon class="empty-icon">mosque</mat-icon>
      <p class="empty-message">{{ t('manage_masjids.no_masjids_to_manage') }}</p>
    </div>
  </ng-template>

  <!-- Loading Template -->
  <ng-template #loading>
    <div class="loading-state">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
