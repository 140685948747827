<ng-container [formGroup]="masjidBasicInfo">
  <mat-card class="basic-info-card" *transloco="let t; read: 'masjidData'">
    <mat-card-header class="flex-center">
      <mat-card-title>
        <h1 class="text-2xl sm:text-3xl">{{ t('basicInformation') }}</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row" style="margin-top: 1.5rem">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('nameLabel') }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ t('nameLabel') }}"
              formControlName="name"
            />
            <mat-error
              *ngIf="masjidBasicInfo.get('name')?.errors?.['required'] && masjidBasicInfo.get('name')?.touched"
            >
              {{ t('nameLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
            <mat-error
              *ngIf="masjidBasicInfo.get('name')?.errors?.['minlength'] && masjidBasicInfo.get('name')?.touched"
            >
              {{ t('nameLabel') + ' ' + t('errorMinValue') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('descriptionLabel') }}</mat-label>
            <textarea
              matInput
              matTextareaAutosize
              placeholder="{{ t('descriptionLabel') }}"
              formControlName="description"
              cdkTextareaAutosize
              cdkAutosizeMinRows="3"
              #autosize="cdkTextareaAutosize"
            >
            </textarea>
            <mat-error *ngIf="masjidBasicInfo.get('description')?.hasError('required')">
              {{ t('descriptionLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
            <mat-error *ngIf="masjidBasicInfo.get('description')?.hasError('maxLength')">
              {{ t('descriptionMaxLength') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('emailLabel') }}</mat-label>
            <input
              matInput
              type="email"
              placeholder="{{ t('emailLabel') }}"
              formControlName="email"
            />
            <mat-error
              *ngIf="masjidBasicInfo.get('email')?.errors?.['email'] && masjidBasicInfo.get('email')?.touched"
            >
              {{ t('emailLabel') + ' ' + t('errorIsInvalid') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label for="openHour">{{ t('openHourLabel') }}</mat-label>
            <mat-select
              formControlName="openHour"
              [value]="selectedOpenHour"
              matNativeControl
              id="openHour"
              (selectionChange)="onOpenHourChange()"
            >
              <mat-option *ngFor="let hour of openHour" [value]="hour">
                {{ hour }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="masjidBasicInfo.get('openHour')?.errors?.['timeInvalid']"
            >
              {{ t('timeInvalid') }}
            </mat-error>
            <mat-error
              *ngIf="masjidBasicInfo.get('openHour')?.errors?.['required'] && masjidBasicInfo.get('openHour')?.touched"
            >
              {{ t('openHourLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('closeHourLabel') }}</mat-label>
            <mat-select
              formControlName="closeHour"
              [value]="selectedCloseHour"
              matNativeControl
              (selectionChange)="onCloseHourChange()"
            >
              <mat-option *ngFor="let hour of closeHour" [value]="hour">
                {{ hour }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="masjidBasicInfo.get('closeHour')?.errors?.['timeInvalid']"
            >
              {{ t('timeInvalid') }}
            </mat-error>
            <mat-error
              *ngIf="masjidBasicInfo.get('closeHour')?.errors?.['required'] && masjidBasicInfo.get('closeHour')?.touched"
            >
              {{ t('closeHourLabel') + ' ' + t('errorIsRequired') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="full-width"
          >
            <mat-label>{{ t('masjidFacilities') }}</mat-label>
            <mat-select formControlName="masjidFacilities" multiple>
              <mat-option *ngFor="let facility of availableFacilities" [value]="facility">
                {{ t('facilities.' + facility) }}
              </mat-option>
            </mat-select>
            <mat-hint>{{ 'common.optional' | transloco }}</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <app-photo-upload
        [bucketId]="bucketId"
        [multipleFileUpload]="true"
        [fileLimit]="10"
        [title]="'Masjid Images'"
        [isRequired]="false"
        [parentForm]="masjidBasicInfo"
        (sendPhotosToParentForm)="sendFilesToParentForm($event)"
        [initFiles]="initFiles"
        [photoType]="photoType"
      ></app-photo-upload>
    </mat-card-content>
    <mat-card-actions
      class="flex-center full-width"
      style="padding-bottom: 1.5rem"
    >
      <div class="row">
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="warn"
            routerLink="/home"
          >
            {{ t('cancel') }}
          </button>
        </div>
        <div class="col flex-center">
          <button
            type="button"
            mat-raised-button
            color="accent"
            (click)="doChangeStep(StepDirection.Next)"
          >
            {{ t('next') }}
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-container>
