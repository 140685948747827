import { Observable } from 'rxjs/internal/Observable'
import {
    MasjidDataInterface,
    MasjidLessonsInterface,
    initialMasjidDataInterface,
} from 'src/app/masjids/interfaces/masjidData.interface'
import {
    PhotoInterface,
    PhotoType,
} from 'src/app/shared/interfaces/Image.interface'

export const initialCreateMasjidState: CreateMasjidStateInterface = {
    isSubmittingSave: false,
    backendError: null,
    masjidData: initialMasjidDataInterface,
    masjidPhotos: [],
    isUploadSuccessful: false,
    loading: false
}
export const initialEditMasjidState: EditMasjidStateInterface = {
    uploadFiles: [],
    deleteFiles: [],
    newLessons: [],
    updateLessons: [],
    deleteLessons: [],
    masjidData: initialMasjidDataInterface,
    isSubmittingSave: false,
    updated: false,
    error: null,
    loading: false
}

export interface EditMasjidStateInterface {
    isSubmittingSave: boolean
    masjidData: MasjidDataInterface
    newLessons: MasjidLessonsInterface[]
    deleteLessons: MasjidLessonsInterface[]
    updateLessons: MasjidLessonsInterface[]
    uploadFiles: Observable<PhotoOperation>[]
    deleteFiles: Observable<PhotoOperation>[]
    updated: boolean
    error: any | null
    loading: boolean
}

export interface CreateMasjidStateInterface {
    isSubmittingSave: boolean
    backendError: string | null
    masjidData: MasjidDataInterface
    masjidPhotos: PhotoInterface[]
    isUploadSuccessful: boolean
    loading: boolean
}
export interface MasjidStateInterface {
    masjid: MasjidDataInterface | null
    masjidsList: Array<MasjidDataInterface>
    userAdminMasjid: Array<MasjidDataInterface>
    error: any,
    loading: boolean,
    pagination: {
        currentPage: number;
        pageSize: number;
        totalItems: number;
    }
}

export const initialMasjidState: MasjidStateInterface = {
    masjid: null,
    masjidsList: [],
    userAdminMasjid: [],
    error: null,
    loading: false,
    pagination: {
        currentPage: 1,
        pageSize: 12,
        totalItems: 0
    }
}

export interface PhotoOperation {
    operation: string
    imageType: PhotoType
    id: string
}

export interface LessonOperation {
    operation: string
    id: string
}
