import { CommonModule } from '@angular/common'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, Subject, takeUntil } from 'rxjs'
import { MasjidStateInterface } from 'src/app/masjids/interfaces/masjid.state.interface'
import { loadMasjidList } from 'src/app/masjids/store/actions/masjid.actions'
import { MasjidStore } from 'src/app/masjids/store/states/masjid.state'
import { MasjidCardComponent } from '../masjid-card/masjid-card.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { masjidLoadingSelector } from '../../store/selectors'
import { MatPaginatorModule, PageEvent, MatPaginatorIntl } from '@angular/material/paginator'
import { CustomPaginatorIntl } from 'src/app/shared/services/custom-paginator-intl.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'


@UntilDestroy()
@Component({
    selector: 'app-masjid-list',
    templateUrl: './masjid-list.component.html',
    styleUrls: ['./masjid-list.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MasjidCardComponent,
        MatProgressSpinnerModule,
        MatIconModule,
        TranslocoModule,
        MatPaginatorModule
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }
    ]
})
export class MasjidListComponent implements OnInit {
    loading$: Observable<boolean>;
    pagination$: Observable<{ currentPage: number; pageSize: number; totalItems: number }>;

    constructor(
        private _store: Store<MasjidStateInterface>,
        private _componentStore: MasjidStore,
        private _translocoService: TranslocoService,
        private _paginatorIntl: MatPaginatorIntl
    ) {
        this.loading$ = this._store.select(masjidLoadingSelector);
        this.pagination$ = this._componentStore.select(state => state.pagination);

        this._translocoService.langChanges$
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this._paginatorIntl.changes.next();
            });
    }

    ngOnInit(): void {
        this._componentStore.resetState()
        this.loadMasjids(1)
    }

    loadMasjids(page: number) {
        this._store.dispatch(loadMasjidList({ 
            page, 
            pageSize: 12
        }))
    }

    onPageChange(event: PageEvent) {
        this.loadMasjids(event.pageIndex + 1);
    }

    get getMasjidList() {
        return this._componentStore.getMasjidList
    }
}
