import { AbstractControl, ValidationErrors } from '@angular/forms';

export class TimeValidators {
    static validTime(group: AbstractControl): ValidationErrors | null {
        const openHour = group.get('openHour');
        const closeHour = group.get('closeHour');
        const lessonStart = group.get('lessonStart');
        const lessonEnd = group.get('lessonEnd');

        // Handle basic info time validation
        if (openHour && closeHour) {
            const openHourValue = openHour.value;
            const closeHourValue = closeHour.value;

            if (!openHourValue || !closeHourValue) {
                return null;
            }

            if (openHourValue >= closeHourValue) {
                openHour.setErrors({ ...openHour.errors, timeInvalid: true });
                closeHour.setErrors({ ...closeHour.errors, timeInvalid: true });
                return { timeInvalid: true };
            }

            // Clear only timeInvalid error
            const openErrors = { ...openHour.errors };
            const closeErrors = { ...closeHour.errors };
            if (openErrors) delete openErrors['timeInvalid'];
            if (closeErrors) delete closeErrors['timeInvalid'];
            
            openHour.setErrors(Object.keys(openErrors || {}).length ? openErrors : null);
            closeHour.setErrors(Object.keys(closeErrors || {}).length ? closeErrors : null);
        }

        // Handle lessons time validation
        if (lessonStart && lessonEnd) {
            const startTime = lessonStart.value;
            const endTime = lessonEnd.value;

            if (!startTime || !endTime) {
                return null;
            }

            if (startTime >= endTime) {
                lessonStart.setErrors({ ...lessonStart.errors, timeInvalid: true });
                lessonEnd.setErrors({ ...lessonEnd.errors, timeInvalid: true });
                return { timeInvalid: true };
            }

            // Clear only timeInvalid error
            const startErrors = { ...lessonStart.errors };
            const endErrors = { ...lessonEnd.errors };
            if (startErrors) delete startErrors['timeInvalid'];
            if (endErrors) delete endErrors['timeInvalid'];
            
            lessonStart.setErrors(Object.keys(startErrors || {}).length ? startErrors : null);
            lessonEnd.setErrors(Object.keys(endErrors || {}).length ? endErrors : null);
        }

        return null;
    }

    static markTimeFieldsAsTouched(group: AbstractControl): void {
        const openHour = group.get('openHour');
        const closeHour = group.get('closeHour');
        const lessonStart = group.get('lessonStart');
        const lessonEnd = group.get('lessonEnd');

        if (openHour && closeHour) {
            openHour.markAsTouched();
            closeHour.markAsTouched();
        }

        if (lessonStart && lessonEnd) {
            lessonStart.markAsTouched();
            lessonEnd.markAsTouched();
        }

        group.updateValueAndValidity();
    }
} 