import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { MasjidDataInterface } from 'src/app/masjids/interfaces/masjidData.interface'
import { loadAdminMasjids } from 'src/app/masjids/store/actions/masjid.actions'
import { setMasjidData } from '../../store/actions/edit.masjid.actions'
import { Router } from '@angular/router'
import { MatCardModule } from '@angular/material/card'
import { MatListModule } from '@angular/material/list'
import { MatToolbarModule } from '@angular/material/toolbar'
import { CommonModule } from '@angular/common'
import { userAdminMasjidListSelector } from '../../store/selectors'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-manage-masjids',
    templateUrl: './manage-masjids.component.html',
    styleUrl: './manage-masjids.component.scss',
    standalone: true,
    imports: [
        MatCardModule,
        MatListModule,
        MatToolbarModule,
        CommonModule,
        MatGridListModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        TranslocoModule
    ],
})
export class ManageMasjidsComponent {
    masjids$: Observable<MasjidDataInterface[]> = of([])
    constructor(
        private store: Store,
        private _router: Router
    ) {
        this.masjids$ = this.store.select(userAdminMasjidListSelector)
    }

    ngOnInit(): void {
        this.store.dispatch(loadAdminMasjids())
    }
    
    editMasjid(masjidId: string): void {
        this.store.dispatch(
            setMasjidData({
                data: { id: masjidId }
            })
        )
        this._router.navigate(['/masjid/edit', masjidId])
    }
}
