<div class="masjid-list-container" *transloco="let t">
    <ng-container *ngIf="!(loading$ | async); else loadingTemplate">
        <ng-container *ngIf="getMasjidList | async as masjids">
            <div *ngIf="masjids.length > 0; else noMasjidsTemplate" class="masjids-grid">
                <app-masjid-card
                    *ngFor="let masjid of masjids"
                    [masjid]="masjid"
                ></app-masjid-card>
            </div>
            <ng-container *ngIf="pagination$ | async as pagination">
                <mat-paginator
                    [length]="pagination.totalItems"
                    [pageSize]="pagination.pageSize"
                    [pageSizeOptions]="[12, 24, 36, 48]"
                    (page)="onPageChange($event)"
                    class="masjid-paginator">
                </mat-paginator>
            </ng-container>
        </ng-container>
    </ng-container>

    <!-- Loading Template -->
    <ng-template #loadingTemplate>
        <div class="loading-state">
            <mat-spinner diameter="48"></mat-spinner>
            <p class="loading-message">{{ t('masjids.loading') }}</p>
        </div>
    </ng-template>

    <!-- No Masjids Template -->
    <ng-template #noMasjidsTemplate>
        <div class="empty-state">
            <mat-icon class="empty-icon">mosque</mat-icon>
            <p class="empty-message">{{ t('masjidData.noMasjids') }}</p>
        </div>
    </ng-template>
</div>
