import { Routes } from "@angular/router";
import { AuthenticatedGuard } from "../shared/guards/authenticated.guard";
import { CreateMasjidComponent } from "./components/create-masjid/create-masjid.component";
import { DetailComponent } from "./components/detail/detail.component";
import { MasjidListComponent } from "./components/masjid-list/masjid-list.component";
import { EditMasjidComponent } from "./components/edit-masjid/edit-masjid.component";
import { ManageMasjidsComponent } from "./components/manage-masjids/manage-masjids.component";

export const masjidRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: '',
        children: [
            { path: 'manage-masjids', component: ManageMasjidsComponent, canActivate: [AuthenticatedGuard] },
            { path: 'create', component: CreateMasjidComponent, canActivate: [AuthenticatedGuard] },
            { path: 'details/:masjidId', component: DetailComponent },
            { path: 'list', component: MasjidListComponent },
            { path: 'edit/:masjidId', component: EditMasjidComponent, canActivate: [AuthenticatedGuard] },
        ],
    },
];