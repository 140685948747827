import { CommonModule } from '@angular/common'
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { MatCardModule } from '@angular/material/card'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatTableModule } from '@angular/material/table'
import { MatChipsModule } from '@angular/material/chips'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { Router, ActivatedRoute } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { of } from 'rxjs/internal/observable/of'
import { catchError, concatMap, switchMap, take, tap } from 'rxjs/operators'
import {
    MasjidDataInterface,
    MasjidLessonsInterface,
} from 'src/app/masjids/interfaces/masjidData.interface'
import { NewsInterface } from 'src/app/news/interfaces/news.interface'
import { FileStorageService } from 'src/app/shared/services/file-storage.service'
import { LessonsService } from 'src/app/shared/services/lessons.service'
import { MasjidDocumentService } from 'src/app/shared/services/masjid.service'
import { NewsService } from 'src/app/shared/services/news.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { GeolocationService } from 'src/app/shared/services/geolocation.service'
import { environment } from 'src/environments/environment'

@UntilDestroy()
@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
    standalone: true,
    imports: [
        MatCardModule,
        MatIconModule,
        MatDividerModule,
        MatTableModule,
        CommonModule,
        MatDividerModule,
        TranslocoModule,
        MatChipsModule,
        MatButtonModule,
        MatTooltipModule
    ],
})
export class DetailComponent implements OnInit {
    @ViewChild('mapContainer') mapContainer!: ElementRef;
    
    id: string
    data: MasjidDataInterface
    photosUrl: Array<string> = []
    leaderPhotoUrl: string | null = null
    hasRegisteredLessons: boolean = false
    latestNews: NewsInterface[] = []
    showMap: boolean = false;
    isMapLoading: boolean = false;
    // the table will be organized according to the order here
    displayedColumns: string[] = [
        'name',
        'instructor',
        'language',
        'duration',
        'start',
        'end',
        'days',
    ]

    lessonsDataSource: MasjidLessonsInterface[] = [
        {
            name: 'Arabic lesson',
            start: '13:00',
            end: '14:00',
            language: 'Portuguese',
            instructor: 'Mohammed',
            duration: '1 hour',
            masjid_id: '981257642983747',
            days: ['Saturday', 'Monday'],
            lessonId: 'ace2d785-4a3f-4096-9fa8-2e4198d69c40',
        },
        {
            name: 'Biography of the prophet Muhammad lesson',
            start: '12:00',
            end: '13:00',
            language: 'Portuguese',
            instructor: 'Mohammed',
            duration: '1 hour',
            masjid_id: '981257642983741',
            days: ['Saturday', 'Sunday'],
            lessonId: 'a6f0fd66-5f1b-4c0b-af34-286d3b48262e',
        },
        {
            name: 'Eschatology lesson',
            start: '11:00',
            end: '12:00',
            language: 'Portuguese',
            instructor: 'Mohammed',
            duration: '1 hour',
            masjid_id: '981257642982647',
            days: ['Saturday', 'Sunday', 'Monday'],
            lessonId: '367f36c8-7cac-4af6-b9cb-018964ca58b4',
        },
        {
            name: 'Quran recitation lesson',
            start: '17:00',
            end: '18:00',
            language: 'Arabic',
            instructor: 'Mohammed',
            duration: '1 hour',
            masjid_id: '981257642983447',
            days: ['Sunday', 'Monday'],
            lessonId: '677b776a-de69-4238-93e0-921283e4d899',
        },
        {
            name: 'Sunna lesson',
            start: '18:00',
            end: '19:00',
            language: 'Portuguese',
            instructor: 'Mohammed',
            duration: '1 hour',
            masjid_id: '981257642983547',
            days: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ],
            lessonId: '098433cb-a700-4655-80ed-d9640809f768',
        },
    ]

    clickedRows = new Set<MasjidLessonsInterface>()

    constructor(
        private _masjidService: MasjidDocumentService,
        private _notificationService: NotificationService,
        private _fileService: FileStorageService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _lessonService: LessonsService,
        private _newsService: NewsService,
        private _geolocationService: GeolocationService
    ) {}

    ngOnInit(): void {
        this._route.paramMap
            .pipe(
                take(1), // Take only the first value to prevent memory leaks
                untilDestroyed(this), // Ensures automatic unsubscription
                tap((params) => {
                    const masjidId = params.get('masjidId')
                    if (!masjidId) {
                        this._router.navigate(['/pageNotFound'])
                        return
                    }
                    this.id = masjidId
                }),
                switchMap(() => {
                    if (!this.id) return of(null)

                    this._notificationService.loading()
                    return this._masjidService.getMasjidDocumentById(this.id)
                }),
                concatMap((data) => {
                    if (!data) {
                        this._notificationService.notifyFailure(
                            'Masjid not Found!'
                        )
                        return of(null)
                    }
                    this.data = data

                    // Fetch images
                    this.photosUrl = this.data.ImagesIds.map((id) =>
                        this._fileService.getFilePreviewUrl(
                            environment.masjid_images_bucket,
                            id
                        )
                    )

                    this.leaderPhotoUrl = this.data.leaderImageId
                        ? this._fileService.getFilePreviewUrl(
                              environment.masjid_leader_images_bucket,
                              this.data.leaderImageId
                          )
                        : null

                    return this._lessonService.getLessonsByMasjidId(
                        this.data.id
                    )
                }),
                tap((lessons) => {
                    console.log('Retrieved lessons:', lessons)
                    if (lessons?.length) {
                        this.data!.lessons = lessons
                        this.hasRegisteredLessons = true
                        console.table(this.data.lessons)
                    } else {
                        console.log('No lessons found.')
                    }
                }),
                switchMap(() => {
                    return this._newsService.getLatestNews(this.data.id)
                }),
                tap((news) => {
                    if (news.length) {
                        this.latestNews = news.filter(
                            (n) => n.masjid_id === this.id
                        ) // Filter news by Masjid ID
                    }
                }),
                catchError((error) => {
                    this._notificationService.removeLoading()
                    this._notificationService.notifyFailure(
                        'Error fetching masjid details'
                    )
                    return of(null)
                })
            )
            .subscribe(() => {
                this._notificationService.removeLoading()
            })
    }

    openInstagram() {
        window.open(`${this.data.instagramUrl}`, '_blank')
    }
    openFacebook() {
        window.open(`${this.data.facebookUrl}`, '_blank')
    }
    openWhatsApp() {
        window.open(`https://wa.me/${this.data.whatsapp}`, '_blank')
    }

    // Navigate to News Details
    viewNewsDetails(newsId: string): void {
        this._router.navigate(['/news/details', newsId])
    }

    getFacilityIcon(facility: string): string {
        const iconMap: { [key: string]: string } = {
            parking: 'local_parking',
            wheelchair: 'accessible',
            wudu: 'water_drop',
            womens_area: 'woman',
            prayer_hall: 'mosque',
            quran_classes: 'menu_book',
            islamic_library: 'local_library',
            funeral_service: 'volunteer_activism',
            community_hall: 'groups',
            children_area: 'child_care',
            shoe_rack: 'shopping_bag',
            ac: 'ac_unit',
            wifi: 'wifi',
            restaurant: 'restaurant',
            shop: 'store'
        };
        return iconMap[facility.toLowerCase()] || 'check_circle';
    }

    hasValidCoordinates(): boolean {
        return !!(this.data?.latitude && this.data?.longitude && 
            this.data.latitude !== '0' && this.data.longitude !== '0' &&
            this.data.latitude !== '' && this.data.longitude !== '');
    }

    getMapButtonTooltip(): string {
        return this.hasValidCoordinates() 
            ? 'masjidData.viewLocationTooltip'
            : 'masjidData.noLocationCoordinates';
    }

    async toggleMap(): Promise<void> {
        if (this.showMap) {
            this.showMap = false;
            return;
        }

        if (!this.hasValidCoordinates()) {
            this._notificationService.notifyWarning('masjidData.noLocationCoordinates');
            return;
        }

        this.isMapLoading = true;
        this.showMap = true;

        try {
            await new Promise(resolve => setTimeout(resolve, 0));

            await this._geolocationService.initializeMap(this.mapContainer.nativeElement, {
                center: {
                    lat: parseFloat(this.data.latitude!),
                    lng: parseFloat(this.data.longitude!)
                },
                zoom: 16
            });

            this._geolocationService.updateMapLocation({
                lat: parseFloat(this.data.latitude!),
                lng: parseFloat(this.data.longitude!)
            });

        } catch (error) {
            console.error('Error initializing map:', error);
            this._notificationService.notifyFailure('masjidData.mapLoadError');
            this.showMap = false;
        } finally {
            this.isMapLoading = false;
        }
    }
}
