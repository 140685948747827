import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { GoogleMapsLoaderService } from './google-maps-loader.service';
import { GeocodeResult, ParsedAddress, GeolocationError, MapOptions } from '../interfaces/geolocation.interface';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private geocoder: google.maps.Geocoder | null = null;
  private initializationPromise: Promise<void> | null = null;
  private map: google.maps.Map | null = null;
  private marker: google.maps.Marker | null = null;

  constructor(private mapsLoader: GoogleMapsLoaderService) {
    // Initialize the geocoder when the service is created
    this.initGeocoder().catch(error => {
      console.error('Failed to initialize geocoder:', error);
    });
  }

  private async initGeocoder(): Promise<void> {
    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = new Promise<void>(async (resolve, reject) => {
      try {
        await this.mapsLoader.load();
        if (typeof google !== 'undefined' && google.maps) {
          this.geocoder = new google.maps.Geocoder();
          resolve();
        } else {
          reject(new Error('Google Maps API not available after loading'));
        }
      } catch (error) {
        reject(error);
      }
    });

    return this.initializationPromise;
  }

  private async ensureGeocoder(): Promise<google.maps.Geocoder> {
    if (!this.geocoder) {
      await this.initGeocoder();
      if (!this.geocoder) {
        throw new Error('Google Maps API is not loaded');
      }
    }
    return this.geocoder;
  }

  /**
   * Parse a Google Maps formatted address into its components
   * @param formattedAddress The formatted address string from Google Maps
   * @returns Object containing parsed address components
   */
  parseGoogleAddress(formattedAddress: string): ParsedAddress {
    console.log('Parsing address:', formattedAddress);
    const result: ParsedAddress = {};

    try {
        // Split by commas first
        const parts = formattedAddress.split(',').map(part => part.trim());
        
        // Process first part: street name
        const firstPart = parts[0];
        result.street = firstPart;

        // Process second part: house number and neighborhood
        const secoundPart = parts[1];
        if (secoundPart) {
            const [houseNumber, neighborhoodPart] = secoundPart.split('-').map(p => p.trim());
            if (houseNumber) {
                result.houseNumber = houseNumber;
            }
            if (neighborhoodPart) {
                result.neighborhood = neighborhoodPart;
            }
        }

        // Process third part: city and state
        if (parts[2]) {
            const cityStateParts = parts[2].split('-');
            if (cityStateParts.length >= 1) {
                result.city = cityStateParts[0].trim();
            }
            if (cityStateParts.length >= 2) {
                result.state = cityStateParts[1].trim();
            }
        }

        // Process fourth part: postal code
        if (parts[3]) {
            result.postalCode = parts[3].trim();
        }

        // Process fifth part: country
        if (parts[4]) {
            result.country = parts[4].trim();
        }

        console.log('Parsed address components:', {
            original: formattedAddress,
            parts: parts,
            result: result
        });

        return result;

    } catch (error) {
        console.error('Error parsing address:', error);
        return {};
    }
  }

  /**
   * Initialize a map instance in the specified container
   * @param container The HTML element to contain the map
   * @param options Map options (center and zoom level)
   * @returns Promise that resolves when the map is initialized
   */
  async initializeMap(container: HTMLElement, options: MapOptions): Promise<void> {
    await this.mapsLoader.load();
    this.map = new google.maps.Map(container, {
      center: options.center,
      zoom: options.zoom,
      mapTypeControl: true,
      streetViewControl: true,
      fullscreenControl: true,
    });
  }

  /**
   * Update map marker and center
   * @param position The new position for the marker
   */
  updateMapLocation(position: { lat: number; lng: number }): void {
    if (!this.map) {
      console.error('Map not initialized');
      return;
    }

    // Update or create marker
    if (this.marker) {
      this.marker.setPosition(position);
    } else {
      this.marker = new google.maps.Marker({
        position: position,
        map: this.map,
        animation: google.maps.Animation.DROP,
        draggable: false
      });
    }

    // Center map on marker
    this.map.setCenter(position);
    this.map.setZoom(16); // Set appropriate zoom level
  }

  /**
   * Get coordinates from address using Google Geocoding API
   * @param address The address to geocode
   * @returns Observable with latitude, longitude and formatted address
   */
  getCoordinatesFromAddress(address: string): Observable<GeocodeResult> {
    return from(this.ensureGeocoder()).pipe(
      switchMap(geocoder => 
        new Promise<GeocodeResult>((resolve, reject) => {
          geocoder.geocode(
            { address: address },
            (
              results: google.maps.GeocoderResult[] | null,
              status: google.maps.GeocoderStatus
            ) => {
              if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
                const location = results[0].geometry.location;
                resolve({
                  lat: location.lat(),
                  lng: location.lng(),
                  formattedAddress: results[0].formatted_address
                });
              } else {
                reject(new Error(`Geocoding failed: ${status}`));
              }
            }
          );
        })
      ),
      catchError(error => throwError(() => error))
    );
  }

  /**
   * Get address from coordinates using Google Reverse Geocoding API
   * @param lat Latitude
   * @param lng Longitude
   * @returns Observable with formatted address and coordinates
   */
  getAddressFromCoordinates(lat: number, lng: number): Observable<GeocodeResult> {
    return from(this.ensureGeocoder()).pipe(
      switchMap(geocoder =>
        new Promise<GeocodeResult>((resolve, reject) => {
          const latlng = { lat, lng };
          geocoder.geocode(
            { location: latlng },
            (
              results: google.maps.GeocoderResult[] | null,
              status: google.maps.GeocoderStatus
            ) => {
              if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
                resolve({
                  lat: lat,
                  lng: lng,
                  formattedAddress: results[0].formatted_address
                });
              } else {
                reject(new Error(`Reverse geocoding failed: ${status}`));
              }
            }
          );
        })
      ),
      catchError(error => throwError(() => error))
    );
  }

  /**
   * Get current user location using browser's geolocation API
   * @returns Promise with coordinates
   */
  getCurrentLocation(): Promise<GeocodeResult> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser'));
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          
          // Get address for the current location
          this.getAddressFromCoordinates(lat, lng).subscribe({
            next: (result) => resolve(result),
            error: (error) => reject(error)
          });
        },
        (error: GeolocationPositionError) => {
          let errorMessage: string;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = 'User denied the request for geolocation';
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = 'Location information is unavailable';
              break;
            case error.TIMEOUT:
              errorMessage = 'The request to get user location timed out';
              break;
            default:
              errorMessage = 'An unknown error occurred';
          }
          reject(new Error(errorMessage));
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
    });
  }
} 